import * as React from "react";

const Body = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-3 " style={{marginTop:"82px"}}>
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height:"100px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_153539406121359" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_153539406977263" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_153539406977263_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h1 className="headlines" style={{textAlign: "left", fontWeight: "bold", fontFamily: "BrutalType, sans-serif"}} dangerouslySetInnerHTML={{__html: data.title}} />
                                                <div dangerouslySetInnerHTML={{__html: data.description}}></div></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_157472397177341" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_157472397177341_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><div id="locator_loading14757" style={{display: "none"}}><img alt="Loading..." width="16" height="16" src="https://cdn.metalocator.com/images/ajax-loader.gif" /></div><iframe width="100%" allow="geolocation *;" title="PSC Locator" name="locator_iframe14757" scrolling="no" id="locator_iframe14757" frameborder="0" src="https://code.metalocator.com/index.php?option=com_locator&amp;view=directory&amp;layout=combined_bootstrap&amp;Itemid=14757&amp;tmpl=component&amp;framed=1&amp;source=js" allowtransparency="true" style={{overflow: "hidden", height: "1000px"}}></iframe><div id="ml___wrapper14757"></div></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_157472397581944" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1536851311052150" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_153539406445561" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Body;