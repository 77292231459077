import * as React from "react";
import Layout from "../../components/global/Layout";
import Body from "../../components/locators/4kscore-test-center/Body";
import { useScoreQuery } from "../../hooks/useScoreQuery";
import Helmet from "react-helmet";

const ScoreTestCenterPage = () => {
    const data = useScoreQuery();
    return (
        <Layout>
            <Body data={data.testCenterFields} />
            <Helmet>
                <title>{data.testCenterMetaData.metaTitle}</title>
                <meta name="description" content={data.testCenterMetaData.metaDescription} />
            </Helmet>
        </Layout>
    );
};

export default ScoreTestCenterPage;