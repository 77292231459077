import { useStaticQuery, graphql } from "gatsby"

export const useScoreQuery = () => {


    const data = useStaticQuery(graphql`
    query ScoreQuery {
        wpPage(title: {eq: "4KSCORE TEST CENTER"}) {
          ACF_ScoreTest {
            testCenterFields {
                title
                description
            }
            testCenterMetaData{
                metaTitle
                metaDescription
            }
        }
        }
    }     
    `)
    return data.wpPage.ACF_ScoreTest;
}